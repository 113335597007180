import { useState, useEffect, useCallback, useContext, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import { getApi, getApiforFile, postApi } from "../../../AxiosRequest";
import swal from "sweetalert2";
import swalAlert from "../../common/SwalAlert";
import { InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { printDate, printDate2,SubsDates } from "../../common/Util";
import AuthContext from "../../common/AuthProvider";
import { GDSContextType } from "../../common/@types.usr";
import { decryptString } from "../../common/cryptoUtils";
import Toast from "../../common/Toast";
import ContractRenewalModel from "../Models/ContractRenewalModel";
import OfferEntry from "../Offers/OfferEntry";
import UploadModel from "../Models/UploadModel";
import TableComponent from "../../common/TableComponent";
import OfferEntryRenewal from "../Offers/OfferEntryRenewal";
import Loading from "../../common/Loading";

type typeParams = {
  paramOfferID: any;
  paramContractID: any;
  paramCompanyID: any;
  paramRenewal?: any;
};

interface IOffers {
  CreatedDate: Date;
  CreatedBy: string;
  UpdatedDate: Date;
  UpdatedBy: string;
  ContractID: number;
  OfferID: number;
  CompanyID: number;
  LocationState: string;
  EmployeeNumber: string;
  EmployeeLastName: string;
  EmployeeFirstName: string;
  ContractStatus: string;
  BaseSalary: number;
  DailyRate: number;
  ODContract_ProductionThreshold: number;
  ODContract_ProductionPercentage: number;
  ODContract_ProductionPaymentFrequency: string;
  EffectiveDate: Date | any;
  RenewalDate: Date | any;
  InitialTerm: string;
}

function Contract(props: any) {
  const { auth } = useContext(AuthContext) as GDSContextType;
  const [show, setShow] = useState(false);
  const [isCompleted, setisCompleted] = useState(false);
  const [isValidate, setIsValidate] = useState(true);
  const [isAllCompleted, setisAllCompleted] = useState(false);
  const [message, setMessage] = useState("");
  const [rowData, setRowData] = useState([]);
  const [rowDataSigned, setRowDataSigned] = useState([]);
  const UpdatePermit = auth?.Userdata?.RoleID;
  const [loading, setLoading] = useState(false);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  const [files, setFiles] = useState([]);
  const [refresh, setrefresh] = useState(0);
  const [refreshOfferEntry, setRefreshOfferEntry] = useState(0);
  const [showUpload, setShowUpload] = useState(false);
  const [showOtherUpload, setshowOtherUpload] = useState(false);
  const [otherDocumentType, setOtherDocumentType] = useState([]);
  const handleUploadShow = useCallback(() => {
    if (isValidate === false) {
      swal.fire(
        "Please update 'Effective Date' and 'Initial Term' in offer before upload contract.",
        "",
        "error"
      );
      return;
    }
    setShowUpload(true);
  }, []);
  const handleUploadClose = useCallback(() => {
    setShowUpload(false);
  }, []);
  const handleOtherUploadShow = useCallback(() => {
    if (isValidate === false) {
      swal.fire(
        "Please update 'Effective Date' and 'Initial Term' in offer before upload contract.",
        "",
        "error"
      );
      return;
    }
    setshowOtherUpload(true);
  }, []);
  const handleOtherUploadClose = useCallback(() => {
    setshowOtherUpload(false);
  }, []);

  const [validated, setValidated] = useState(false);
  const [offerData, setofferData] = useState<IOffers>();
  const [offerAddData, setofferAddData] = useState({
    EffectiveDate: printDate2(new Date()),
    InitialTerm: "",
    OfferID: "",
    userId: auth?.Userdata?.UserId,
    Notes:""
  });

  const [contractData, setContractData] = useState({
    ContractID: 0,
    OfferID: 0,
    CompanyID: 0,
    ForCause: "",
    ForConvenienceofPractice: "",
    ForConvenienceofOD: "",
    NonCompetition: "",
    NonSolicitationOfPractive: "",
    NonSolicitationofPatients: "",
    NonSolicitationofEmployees: "",
    DefSolicit: "",
    DefSolicit1: "",
    DefSolicit2: "",
    NonInterfernce: "",
    NonDisparagement: "",
    RepWarranties: "",
    RepWarranties1: "",
    RepWarranties2: "",
    RepWarranties3: "",
    RepWarranties4: "",
    RepWarranties5: "",
    RepWarranties6: "",
    RepWarranties7: "",
    RepWarranties8: "",
  });
  const { paramOfferID, paramContractID, paramCompanyID, paramRenewal } =
    useParams<typeParams>();

  const BackPage = () => {
    window.history.back();
  };
  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };
  const handleRefreshOfferEntry = () => {
    setRefreshOfferEntry((oldkey) => oldkey + 1);
  };
  const [toastKey, setToastKey] = useState<number>(0);
  const handleClick = () => {
    setToastKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    async function populateDocumentType() {
      await getApi("/api/Common/GetLookupList?name=OtherDocument")
        .then((response) => {
          let data = response.data;
          setOtherDocumentType(data);
        })
        .catch((error) => {
          swalAlert(error);
        });
    }
    let IsCancel = false;
    if (!IsCancel) {
      populateDocumentType();
    }
    return () => {
      IsCancel = true;
    };
  }, []);

  useEffect(() => {
    async function PopulateOtherDocument(companyID: any, contractID: any) {
      if (companyID !== 0 && contractID !== 0) {
        let param = {
          contractID: contractID || "",
          companyID: companyID || "",
        };
        await postApi("/api/UploadFile/ListOtherFilesfromStorage", null, param)
          .then((response) => {
            let data = response.data;
            for (let index = 0; index < data.length; index++) {
              data[index].CreatedDate = printDate(data[index].CreatedDate);
            }
            setRowData(data);
          })
          .catch((error) => {
            swalAlert(error);
          });
      }
    }
    async function PopulateSignedDocumentFromStorage(
      companyID: any,
      contractID: any
    ) {
      if (companyID !== 0 && contractID !== 0) {
        let param = {
          contractID: contractID || "",
          companyID: companyID || "",
        };
        await postApi(
          "/api/UploadFile/ListSignedDocFilesfromStorage",
          null,
          param
        )
          .then((response) => {
            let data = response.data;
            for (let index = 0; index < data.length; index++) {
              data[index].CreatedDate = printDate(data[index].CreatedDate);
            }
            setRowDataSigned(data);
          })
          .catch((error) => {
            swalAlert(error);
          });
      }
    }

    let IsCancel = false;
    if (!IsCancel) {
      let decryptedParamContractID = decryptString(paramContractID);
      let decryptedParamCompanyID = decryptString(paramCompanyID);
      PopulateOtherDocument(decryptedParamCompanyID, decryptedParamContractID);
      PopulateSignedDocumentFromStorage(
        decryptedParamCompanyID,
        decryptedParamContractID
      );
    }
    return () => {
      IsCancel = true;
    };
  }, [refresh]);

  useEffect(() => {
    async function populateOffers(id: any) {
      await getApi("/api/Contracts/getOffersById?Id=" + id)
        .then((response) => {
          let data = response.data;

          if (data.EffectiveDate === null) {
            setIsValidate(false);
          } else if (data.InitialTerm === null || data.InitialTerm === "") {
            setIsValidate(false);
          } else {
            setIsValidate(true);
          }
          data.HireDate = printDate(data.HireDate);
          data.ContractStatusId === "1"
            ? setisCompleted(true)
            : setisCompleted(false);

          setofferData(data);
          

          let daysBetween = SubsDates(data.ODContract_RenewalDate,data.HireDate);

          offerAddData.EffectiveDate =printDate2(data.EffectiveDate)?? printDate2(data.HireDate);
          offerAddData.InitialTerm = data.InitialTerm?? daysBetween.toString();
          offerAddData.OfferID = data.OfferID;
          offerAddData.Notes = data.Notes;
          setofferAddData(offerAddData);
        })
        .catch((error) => {
          swalAlert(error);
        });
    }

    let IsCancel = false;
    if (!IsCancel) {
      let decryptedParamOfferID = decryptString(paramOfferID);
      populateOffers(decryptedParamOfferID);
    }
  }, [refreshOfferEntry]);

  useEffect(() => {
    async function populateOffers(id: any) {
      await getApi("/api/Contracts/getOffersById?Id=" + id)
        .then((response) => {
          let data = response.data;
         
          if (data.EffectiveDate === null) {
            setIsValidate(false);
          } else if (data.InitialTerm === null || data.InitialTerm === null) {
            setIsValidate(false);
          }
          data.HireDate = printDate(data.HireDate);
          data.ContractStatusId === "1"
            ? setisCompleted(true)
            : setisCompleted(false);

          setofferData(data);
        })
        .catch((error) => {
          swalAlert(error);
        });
    }

    async function populateContractDefaltData() {
      await getApi("/api/common/GetContractDefaultData")
        .then((response) => {
          let data = response.data;
          setContractData(data);
        })
        .catch((error) => {
          swalAlert(error);
        });
    }

    async function populateContract(id: any) {
      await getApi("/api/Contracts/getContractById?Id=" + id)
        .then((response) => {
          let data = response.data;
          debugger;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
          }

          paramRenewal === "true"
            ? setisAllCompleted(false)
            : data.ContractStatusId === "5"
              ? setisAllCompleted(true)
              : setisAllCompleted(false);
            
              //  UpdatePermit === "1"
              //  ? setisAllCompleted(false)
              //    : setisAllCompleted(true);

          if (data.ContractStatusId === "1") {
            populateContractDefaltData();
          } else {
            setContractData(data);
          }
        })
        .catch((error) => {
          swalAlert(error);
        });
    }

    let IsCancel = false;
    if (!IsCancel) {
      if (
        paramOfferID !== undefined &&
        paramOfferID !== 0 &&
        typeof paramOfferID !== null
      ) {
        let decryptedParamOfferID = decryptString(paramOfferID);
        let decryptedParamContractID = decryptString(paramContractID);
        populateOffers(decryptedParamOfferID);
        populateContract(decryptedParamContractID);
      }
    }
    return () => {
      IsCancel = true;
    };
  }, [paramOfferID]);

  const SaveContract = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (isValidate === false) {
        swal.fire(
          "Please update 'Effective Date' and 'Initial Term' in offer before save contract.",
          "",
          "error"
        );
        return;
      }

      contractData.OfferID = offerData?.OfferID ?? 0;
      contractData.CompanyID = offerData?.CompanyID ?? 0;

      let parms = {
        userId: auth?.Userdata?.UserId,
        IsRenewal: paramRenewal === undefined ? false : true,
      };
      await postApi("/api/Contracts/SaveContract", contractData, parms)
        .then(async (response) => {
          // Swal.fire(response.data, "", "success");
          handleClick();
          setMessage(response.data);
          setisCompleted(true);
        })
        .catch(async (error) => {
          swalAlert(error);
        })
        .finally(async () => { });
    }
    setValidated(true);
  };
  const SaveEffectiveAndTermData = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    // if (isValidate === false) {
    //   swal.fire(
    //     "Please enter 'Effective Date' and 'Initial Term'",
    //     "",
    //     "error"
    //   );
    //   return;
    // }
   

    if (offerAddData.InitialTerm === null || offerAddData.InitialTerm === "") {
      swal.fire(
        "Please enter 'Effective Date' and 'Initial Term'",
        "",
        "error"
      );
      return;
    }

    let decryptedParamOfferID = decryptString(paramOfferID);
    if (offerAddData.EffectiveDate == undefined) {
      offerAddData.EffectiveDate = printDate2(new Date());
    }
    setofferAddData({
      ...offerAddData,
      OfferID: decryptedParamOfferID || "",
    });

    await postApi("/api/Offers/UpdateOfferData", offerAddData)
      .then(async (response) => {
        handleClick();
        setIsValidate(true);
        setMessage(response.data);
      })
      .catch(async (error) => {
   
        let strError = "";
        if (error.response) {
          strError = `Server Error: ${error.response.status} - ${error.response.data}`;
        } else if (error.request) {
          strError = "No response received from the server";
        } else {
          strError = `Error: ${error.message}`;
        }
        swal.fire(strError, "", "error");
      })
      .finally(async () => { });
  };

  const DownloadContract = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
  
    if (isValidate === false) {

      swal.fire(
        "Please update 'Effective Date' and 'Initial Term' in offer before download contract.",
        "",
        "error"
      );
      return;
    }
    const decryptedParamContractID = decryptString(paramContractID);
    let contractId = decryptedParamContractID;
    let IsRenewal = paramRenewal === undefined ? false : true;
    let docType= event.currentTarget.id;

    const fileName =
      offerData?.EmployeeFirstName +
      "" +
      offerData?.EmployeeLastName +
      "Contract.docx";

      setLoading(true);
    getApiforFile(
      "/api/Contracts/DownloadSignedContract?contractId=" +
      contractId +
      "&DocType="+docType+
      "&IsRenewal=" +
      IsRenewal
      
    )
      .then(async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));       
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch(async (error) => {
        swalAlert(error);
        setLoading(true);
      })
      .finally(async () => { });
  };

  const completeContract = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    if (isValidate === false) {
      swal.fire(
        "Please update 'Effective Date' and 'Initial Term' in offer before complete contract.",
        "",
        "error"
      );
      return;
    }
    const decryptedParamContractID = decryptString(paramContractID);
    let contractId = decryptedParamContractID;
    let IsRenewal = paramRenewal === undefined ? false : true;
    await getApi(
      "/api/Contracts/CompleteContract?contractId=" +
      contractId +
      "&IsRenewal=" +
      IsRenewal
    )
      .then(async (response) => {
        swal.fire(response.data, "", "success");
      })
      .catch(async (error) => {
        swalAlert(error);
      })
      .finally(async () => { });
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      flex: 1,
    };
  }, []);

  const columnHeadersSigned: string[] = [
    "Document Type",
    "Added By",
    "Added On",
    "Download"
    
  ];
  const columnHeaders: string[] = [
    "Document Type",
    "Added By",
    "Added On",
    "Download",
    "Delete"
  ];
  return (
    <>  {loading && <Loading />}
      <Toast message={message} toastKey={toastKey} />

      {showUpload && (
        <UploadModel
          showUpload={showUpload}
          contractId={paramContractID}
          companyId={paramCompanyID}
          handleRefresh={handleRefresh}
          handleUploadClose={handleUploadClose}
          title="Upload Signed Contract"
          UploadOtherDocument={false}
          offerId={paramOfferID}
        />
      )}

      {showOtherUpload && (
        <UploadModel
          showUpload={showOtherUpload}
          contractId={paramContractID}
          companyId={paramCompanyID}
          handleRefresh={handleRefresh}
          handleUploadClose={handleOtherUploadClose}
          title="Upload Other Document"
          UploadOtherDocument={true}
          otherDocumentType={otherDocumentType}
          offerId={paramOfferID}
        />
      )}
      {show === true && (
        <ContractRenewalModel showModal={show} closeModal={handleClose}>
          <OfferEntry
            paramRenewal={
              paramRenewal === undefined || paramRenewal === null ? false : true
            }
            newTitle="Offer"
            closeModal={handleClose}
            handleRefreshOfferEntry={handleRefreshOfferEntry}
          />
        </ContractRenewalModel>
      )}
      <Form noValidate validated={validated}>
        <div className="container-fluid">
          <div className="col-lg-12">
            <div className="row mt-2">
              <div className="col-md-8">
                <input type="hidden" value={offerData?.OfferID} />
                <div className="row">
                  <Form.Group as={Col} md="2" controlId="validationCustom01">
                    <Form.Label>Employee #</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      value={offerData?.EmployeeNumber}
                      placeholder="Employee Number"
                      onChange={(e) =>
                        props.seteditOfferData({
                          ...offerData,
                          EmployeeNumber: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="validationCustom02">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      value={offerData?.EmployeeFirstName}
                      placeholder="First Name"
                      onChange={(e) =>
                        props.seteditOfferData({
                          ...offerData,
                          EmployeeFirstName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="validationCustom03">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      value={offerData?.EmployeeLastName}
                      placeholder="Last Name"
                      onChange={(e) =>
                        props.seteditOfferData({
                          ...offerData,
                          EmployeeLastName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="validationCustom04">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      value={offerData?.LocationState}
                      placeholder="State"
                      onChange={(e) =>
                        props.seteditOfferData({
                          ...offerData,
                          LocationState: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="validationCustom04">
                    <Button
                      style={{ marginTop: "22px" }}
                      size="sm"
                      variant="outline-danger"
                      onClick={handleShow}
                    >
                      View Details
                    </Button>
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Button
                      style={{
                        display:
                          paramRenewal === "true"
                            ? "block"
                            : UpdatePermit !== 4
                              ? "none"
                              : "block",
                        marginTop: "22px",
                      }}
                      size="sm"
                      variant="primary"
                      onClick={SaveContract}
                    >
                      Save Modified Contract
                    </Button>
                  </Form.Group>
                </div>
              </div>
              <div className="col-md-4">
                <InputGroup
                  className="justify-content-end"
                  style={{ marginTop: "22px" }}
                >
                  <Button size="sm" onClick={BackPage} variant="danger">
                    Go Back
                  </Button>
                </InputGroup>
              </div>
            </div>
            <hr />
            <div className="container-fluid">
              <Card>
                <Card.Header>
                  Contract Details
                  <b>
                    {paramRenewal === "true"
                      ? " (Contract modified for Renewal)"
                      : ""}
                  </b>
                </Card.Header>
                <div style={{ overflowY: "auto", height: "70vh" }}>
                  <Card.Body>
                    <Card.Text>
                      <div className="row" style={{ padding: "10px" }}>
                        <div className="col-md-6">
                          <div className="row">
                            <Form.Group as={Col} md="6">
                              <Form.Label>Daily Rate:</Form.Label>{" "}
                              <Form.Label> {offerData?.DailyRate}</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label>Base Salary:</Form.Label>{" "}
                              <Form.Label>
                                {" "}
                                {offerData?.BaseSalary || ""}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label>Production Bonus %:</Form.Label>
                              <Form.Label>
                                {" "}
                                {offerData?.ODContract_ProductionPercentage ||
                                  ""}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label>Production Threshold:</Form.Label>{" "}
                              <Form.Label>
                                {" "}
                                {offerData?.ODContract_ProductionThreshold ||
                                  ""}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                              <Form.Label>
                                Production Bonus Frequency:
                              </Form.Label>
                              <Form.Label>
                                {" "}
                                {offerData?.ODContract_ProductionPaymentFrequency ||
                                  ""}
                              </Form.Label>
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                              <Form.Label>Effective Date:</Form.Label>
                              <Form.Control
                                required
                                type="date"
                                value={offerAddData?.EffectiveDate}
                                placeholder="Effective Date"
                                onChange={(e) =>
                                  setofferAddData({
                                    ...offerAddData,
                                    EffectiveDate: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label style={{ color: 'red' }}>Initial Term (Numeric only):</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                value={offerAddData?.InitialTerm}
                                onChange={(e) =>{
                                  setofferAddData({
                                    ...offerAddData,
                                    InitialTerm: e.target.value,
                                  })
                                }}
                              />
                                                          </Form.Group>
                           
                          </div>
                          <Form.Group as={Col} md="12">
                              <Form.Label>Notes:</Form.Label>
                              
                              <Form.Control
                                required
                                type="text"
                                as="textarea"
                                disabled
                                className="h50"
                                value={offerAddData?.Notes}
                                onChange={(e) =>
                                  setofferAddData({
                                    ...offerAddData,
                                    Notes: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                          {isAllCompleted === false && (
                            <div
                              className="justify-content-end"
                              style={{ paddingLeft: "180px" }}
                            >
                              <div className="col-md-6 mt-2">
                                Step 1 -{" "}
                                <Button
                                  size="sm"
                                  variant="primary"
                                  className="btn sameSize"
                                  onClick={SaveEffectiveAndTermData}
                                  type="button"
                                >
                                  Save
                                </Button>
                              </div>
                              <div className="col-md-6 mt-2">
                                Step 2 -{" "}
                                <Button
                                  size="sm"
                                  variant="success"
                                  className="sameSize"
                                  id="SimpleContract"
                                  onClick={DownloadContract}
                                >
                                  Download Contract
                                </Button>{" "}
                                <Button
                                  size="sm"
                                  variant="success"
                                  className="sameSize"
                                  id="AltContract"
                                  onClick={DownloadContract}
                                >
                                  Download Alt Contract
                                </Button>
                              </div>
                              <div className="col-md-6 mt-2">
                                Step 3 -{" "}
                                <Button
                                  size="sm"
                                  variant="warning"
                                  className="sameSize"
                                  onClick={handleUploadShow}
                                >
                                  Upload Signed Contract
                                </Button>
                              </div>
                              <div className="col-md-6 mt-2">
                                Step 4 -{" "}
                                <Button
                                  size="sm"
                                  variant="info"
                                  className="sameSize"
                                  onClick={completeContract}
                                >
                                  Complete
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <Accordion defaultActiveKey={["3", "4"]} alwaysOpen>
                        <Accordion.Item
                          eventKey="0"
                          style={{
                            display:
                              paramRenewal === "true"
                                ? "block"
                                : UpdatePermit !== 4
                                  ? "none"
                                  : "block",
                          }}
                        >
                          <Accordion.Header aria-expanded="true">
                            Offer Details
                          </Accordion.Header>
                          <Accordion.Body>
                            <OfferEntryRenewal
                              isRenewal={paramRenewal}
                              newTitle="Offer"
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="1"
                          style={{
                            display:
                              paramRenewal === "true"
                                ? "block"
                                : (UpdatePermit !== 4
                                  ? "none"
                                  : "block"),
                          }}
                        >
                          <Accordion.Header aria-expanded="true">
                            Article 2 Element
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="col-lg-12">
                              <div className="col-md-12">
                                <div className="row">
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom1"
                                  >
                                    <Form.Label>For Cause:</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      className="h70"
                                      required
                                      value={contractData?.ForCause || ""}
                                      onChange={(e) =>
                                        setContractData({
                                          ...contractData,
                                          ForCause: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div className="row">
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom1"
                                  >
                                    <Form.Label>
                                      For Convenience of Practice:
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      className="h70"
                                      type="text"
                                      value={
                                        contractData?.ForConvenienceofPractice ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        setContractData({
                                          ...contractData,
                                          ForConvenienceofPractice:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </div>
                                <div className="row">
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom1"
                                  >
                                    <Form.Label>
                                      For Convenience of Optometrist:
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      className="h70"
                                      type="text"
                                      value={
                                        contractData?.ForConvenienceofOD || ""
                                      }
                                      onChange={(e) =>
                                        setContractData({
                                          ...contractData,
                                          ForConvenienceofOD: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey="2"
                          style={{
                            display:
                              paramRenewal === "true"
                                ? "block"
                                : UpdatePermit !== 4
                                  ? "none"
                                  : "block",
                          }}
                        >
                          <Accordion.Header>Article 7 Element</Accordion.Header>
                          <Accordion.Body>
                            <div className="col-lg-12">
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>Non Competition:</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.NonCompetition || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        NonCompetition: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>
                                    Non-Solicitation of Practice Optometrists,
                                    Referral Sources:
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={
                                      contractData?.NonSolicitationOfPractive ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        NonSolicitationOfPractive:
                                          e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>
                                    Non Solicitation of Patients:
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={
                                      contractData?.NonSolicitationofPatients ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        NonSolicitationofPatients:
                                          e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>
                                    Non Solicitation of Employees:
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={
                                      contractData?.NonSolicitationofEmployees ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        NonSolicitationofEmployees:
                                          e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>
                                    Definition of “Solicit”:{" "}
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.DefSolicit || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        DefSolicit: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>

                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>
                                    Definition of Solicit 1:
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.DefSolicit1 || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        DefSolicit1: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>

                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>
                                    Definition of Solicit 2:
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.DefSolicit2 || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        DefSolicit2: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>Non Interfernce:</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.NonInterfernce || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        NonInterfernce: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>Non Disparagement:</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.NonDisparagement || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        NonDisparagement: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>

                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>
                                    Representations and Warranties of
                                    Optometrist:{" "}
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.RepWarranties || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        RepWarranties: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>Rep Warranties 1:</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.RepWarranties1 || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        RepWarranties1: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>Rep Warranties 2:</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.RepWarranties2 || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        RepWarranties2: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>Rep Warranties 3:</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.RepWarranties3 || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        RepWarranties3: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>Rep Warranties 4:</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.RepWarranties4 || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        RepWarranties4: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="row">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="validationCustom1"
                                >
                                  <Form.Label>Rep Warranties 5:</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    className="h70"
                                    type="text"
                                    value={contractData?.RepWarranties5 || ""}
                                    onChange={(e) =>
                                      setContractData({
                                        ...contractData,
                                        RepWarranties5: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom1"
                              >
                                <Form.Label>Rep Warranties 6:</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  className="h70"
                                  type="text"
                                  value={contractData?.RepWarranties6 || ""}
                                  onChange={(e) =>
                                    setContractData({
                                      ...contractData,
                                      RepWarranties6: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom1"
                              >
                                <Form.Label>Rep Warranties 7:</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  className="h70"
                                  type="text"
                                  value={contractData?.RepWarranties7 || ""}
                                  onChange={(e) =>
                                    setContractData({
                                      ...contractData,
                                      RepWarranties7: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom1"
                              >
                                <Form.Label>Rep Warranties 8:</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  className="h70"
                                  type="text"
                                  value={contractData?.RepWarranties8 || ""}
                                  onChange={(e) =>
                                    setContractData({
                                      ...contractData,
                                      RepWarranties8: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            Download Signed Contract
                          </Accordion.Header>
                          <Accordion.Body>
                            {rowDataSigned && (
                              <TableComponent
                                rowData={rowDataSigned}
                                columnHeaders={columnHeadersSigned}
                                tableHeight="30vh"
                                tableWidth="55vw"
                                handleRefresh={handleRefresh}
                                isDeleteCol={isAllCompleted}
                                isHiddenCol={true}
                                DeleteType="Contract"
                              />
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            Other Documents{" "}
                            <Button
                              size="sm"
                              variant="primary"
                              onClick={handleOtherUploadShow}
                              style={{ marginLeft: "15px" }}
                            >
                              Upload Other Document
                            </Button>
                          </Accordion.Header>
                          <Accordion.Body>
                            {rowData && (
                              <TableComponent
                                rowData={rowData}
                                columnHeaders={columnHeaders}
                                tableHeight="30vh"
                                tableWidth="55vw"
                                handleRefresh={handleRefresh}
                                isDeleteCol={isAllCompleted}
                                DeleteType="Contract"
                              />
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Card.Text>
                  </Card.Body>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

export default Contract;
